<template>
  <div>
    <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-group"/> Contratos Activos
        <div class="card-header-actions">
          <!-- <CButton color="info" size="sm" @click="storeModal()">Nuevo Contrato</CButton> -->
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable 
        :hover="true"
        :striped="true"
        :border="true"
        :small="true"
        :fixed="true"
        :items="contracts"
        :fields="fields"
        :items-per-page="10"
        :dark="false"
        pagination
      >
        <template #date="{item}"><td class="w100 text-center">{{item.date | date}}</td></template>
        <template #customer_name="{item}"><td>{{item.customer_name}}</td></template>
        <template #description="{item}"><td>{{item.description}}</td></template>
        <template #actions="{item}">
          <td class="table_actions b3">
            <CButton color="info" size="sm" @click="showContract(item)">Ver Visitas</CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
  </div>
</template>
<script>

import store from '../../store'
import router from '../../router/index'
import ws from '../../services/admin';
import moment from 'moment';

export default {
  name: 'Home',
  components: {
    
  },
  data () {
    return {
      contracts: [],
      fields: [
          {key: 'date', label: 'Fecha'},
          {key: 'customer_name', label: 'Cliente'},
          {key: 'description', label: 'Descripción'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
    }
  },
  mounted: async function() {
    this.loading();

    let is_administrator = localStorage.getItem("is_administrator");

    if(is_administrator == "false"){
      let is_customer = localStorage.getItem("is_customer");

      if(is_customer == "false"){
        let is_kam = localStorage.getItem("is_kam");

        if(is_kam == "false"){
          window.location.href = "/#/home"; this.loaded(); return;
        }
        else{
          window.location.href = "/#/welcome"; this.loaded(); return;
        }
      }
      else{
        window.location.href = "/#/customer-graphs/general"; this.loaded(); return;
      }
    }

    let response = await ws.getContracts(); 

    if(response.type == "success"){
      this.contracts = response.data;
    }

    this.loaded();
  },
  methods: {
    async showContract (item) {
      this.loading();

      window.location.href = "/#/contract/visits/"+item.id; return;

      this.loaded();
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
